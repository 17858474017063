@import "mixins.scss";
@import "variables.scss";
@import "flexbox.scss";

html {
  font-size: 100%;
  overscroll-behavior: none;
}

body {
  margin: 0;
  padding: 0;
  font-size: 1rem;
  line-height: 1.5;
  font-family: $font-body;
  -webkit-font-smoothing: antialiased;
}

h3 {
  font-size: 18px;
  color: #000000;
}

h4 {
  font-size: 14px;
  font-weight: 600;
  color: $s-dark-grey-blue;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

.layout {
  @include flexbox();
  @include flex-direction(column);
  padding-top: 50px;
}

@media screen and (max-width: 650px) {
  .layout {
    @include flexbox();
  }
}

.simple-layout {
  .app__main {
    &__wrapper {
      @include flexbox();
      @include flex-direction(row);
      @include flex(1);
      @include justify-content(center);
      width: 100%;
      &__content {
        @include flexbox();
        @include flex-direction(column);
        padding: 32px 50px;
        max-width: 800px;
        width: 100%;
        .content {
          width: 100%;
        }
      }
    }
  }
}

.app-products {
  .app__main {
    &__wrapper {
      width: 100%;
      &__content {
        width: 100%;
      }
      .content {
        width: 100%;
        @media all and (max-width: $mobile-break-point) {
          width: calc(100% - 40px);
        }
      }
    }
  }
}

.app--sidebar-close {
  .app__main__wrapper {
    @include justify-content(center);
  }
}

.layout-2-column {
  .app__main {
    &__wrapper {
      width: 100%;
      &__content {
        padding: 32px 50px;
        max-width: 800px;
        width: 100%;
      }
      .content {
        width: 100%;
      }
    }
  }
}

.app {
  @include flexbox();
  @include flex-direction(column);
  &__topbar {
    @include flexbox();
    @include flex-direction(row);
    @include align-items(center);

    height: 50px;
    background-color: $c-blue;
    color: $c-white;
    padding: 0 24px;
    font-size: 14px;
    &__nav {
      @include flexbox();
      @include header();
      @include justify-content(flex-end);
      > * {
        margin-left: 12px;
        margin-right: 12px;
        position: relative;
        &:not(:first-child):before {
          content: ' | ';
          position: absolute;
          left: -12px;
          opacity: 0.5;
        }
      }
      &__email {
        opacity: 0.6;
      }
      &__logout {
        font-size: 13px;
      }

    }
  }
  &__main {
    @include flexbox();
    @include flex-direction(row);
    @include flex(1);
    &__sidebar {
      @include flex(0 0 301px);
      padding: 24px 21px;
      background: $s-light-grey;
      &--opened {

      }
      &--closed {
        display: none;
      }
      &__title {
        font-size: 11px;
        font-weight: 900;
        color: $s-status-text;
        text-transform: uppercase;
        padding-bottom: 4px;
      }
      &__account {
        font-size: 14px;
        font-weight: 900;
        color: $s-static-heading;
      }
      &__toggle {
        float: right;
      }
      &__menu {
        list-style: none;
        margin: 21px 0 0 0 ;
        padding: 0;
        &__item {
          @include flexbox();
          @include align-items(center);
          margin: 0;
          padding: 0 16px;
          border-radius: 4px;
          height: 48px;
          color: $s-dark-grey-blue;
          margin-bottom: 8px;
          svg {
            fill: $s-dark-grey-blue;
            margin-right: 12px;
          }
          &--active {
            fill: $s-dark-grey-blue;
            background-color: $s-light-periwinkle;
          }
        }
      }
    }
    &__wrapper {
      @include flexbox();
      @include flex-direction(row);
      @include flex(1);
      // margin-left: 52px;
      // > * {
      //   width: 800px;
      // }

      .app-products & {
        padding: 0;
        // background-image: linear-gradient(to bottom, #667898, #15406b);
      }
    }
  }
}
aside.app__menu {
  width: 300px;
  background: #f5f5f5;
  display: block;
  position: fixed;
  top: 0;
  padding: 50px 16px;
}

header.app__header {
  @include header();
}


.content {
  width: 100%;
  max-width: 848px;
  //margin-top: 20vh;
  margin: 20px 50px;

  &--center {
    margin: 0 auto;

    @media all and (max-width: $mobile-break-point) {
      padding: 0 20px;
    }
  }
}

.app-close {
  position: absolute;
  right: 32px;
  top: 82px;
}

.app-header + .route-header
{
  margin-top: 8px;
  font-family: Avenir;
  font-size: 32px;
  font-weight: 500;
  color: $s-static-heading;
}


.form__field {
  margin-top: 24px;
}

.form__field + .button {
  @include align-self(flex-end);
  margin-top: 16px;
}

.form__field + .link {
  @include align-self(flex-end);
  margin-top: 10px;
}

.form__field + .tos
{
  @include align-self(flex-end);
}

.form > .link {
  font-weight: 800;
}

.para + .form {
  margin-top: 24px;
}


.route-header + .para {
  margin-top: 16px;
}

@media all and (max-width: $mobile-break-point) {
  .account-pending-members__user {
    @include flex-direction(column);
  }
  .app__main {
    @include flex-direction(column);
    &__wrapper {
      @include align-items(flex-start);
    }
    &__sidebar {
      flex-basis: auto;
      position: relative;
      &__title {
      }
      &__menu {
      @include flexbox();
      @include flex-direction(row);
      position: absolute;
      top: 0;
      right: 0;
        span {
          display: none;
        }
      }
    }
    .section {
      @include flex-direction(column);
      @include align-items(stretch);
      // width: 100%;

      &.section--members {
        .section__header__subtitle {
          padding-left: 25px;
        }
      }
    }
  }
}

@media all and (max-width: $tablet-break-point) {
  .app__main {
    &__menu {
      // flex: 1 1 200px;
    }
    .section {
      display: block;
      max-width: 800px;
      &__header {
        margin-bottom: 32px;
        &__subtitle {
          padding-left: 0;
        }
      }
      &.section--members {
        .section__header__subtitle {
          padding-left: 25px;
        }
      }
    }
  }
}

@media all and (min-width: $mobile-break-point) {
  .app__main {
    &__wrapper {
      // @include align-items(stretch);
    }
    &__sidebar {
    }
    .section {
      max-width: 800px;
      display: block;
      &__header {
        margin-bottom: 32px;
        &__subtitle {
          padding-left: 0;
        }
      }
      &.section--members {
        .section__header__subtitle {
          padding-left: 25px;
        }
      }
    }
  }
}
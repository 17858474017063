@import "variables.scss";
@import "flexbox.scss";

.section {
  @include flexbox();
  @include flex-direction(row);
  @include align-items(stretch);
  padding-bottom: 32px;
  margin: 32px 0;
  box-sizing: content-box;

  &--standard {
    .section:not(:last-child) {
      border-bottom: solid 1px $s-divider;
    }
  }

  &--info {
    border: solid 1px $s-pinkish-grey;
    background-color: #f7f5f0;
    padding: 32px 24px;
    border-radius: 4px;

  }


  .form {
    &__field:first-child {
      margin-top: 0;
    }
  }

  &__header {
    @include flex(2);
    margin-right: 32px;

    &__title {
      @include flexbox();
      @include flex-direction(row);
      @include align-items(center);

      margin: 0;
      color: $s-static-heading;
      font-size: 18px;
      font-weight: 600;
      &__icon {
        @include flexbox();
        @include flex-direction(row);
        @include justify-self(flex-start);

        min-width: 24px;
        margin-top: -3px;
        svg {
          fill: $s-light-peach;
        }
      }
    }
    &__subtitle {
      margin: 0;
      padding-left: 25px;
      font-size: 14px;
      color: $s-status-text;
    }
  }
  &__content {
    @include flex(3);
    color: $s-static-heading;
    > :first-child {
      margin-top: 0;
    }
    h3 {
      font-weight: 500;
      margin: 0;
    }
    p {
      font-weight: 500;
      opacity: 0.7;
      font-family: Avenir;
      font-size: 14px;
      font-weight: 500;
      margin: 0;
    }
    &__actions {
      @include flexbox();
      @include flex-direction(row);
      @include align-items(center);
      @include justify-self(flex-start);

      margin-top: 32px;
      height: 50px;

      > * + * {
        margin-left: 12px;
      }
    }
  }
}


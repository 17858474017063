@import "variables.scss";

.button {
  cursor: pointer;
  width: auto;
  min-width: 150px;
  height: 48px;
  border-radius: 4px;
  display: block;

  font-size: 14px;
  font-weight: 600;
  text-align: center;
  margin: 0;
  padding: 0 25px;
  border: 1px solid transparent;
  max-width: 270px;
  vertical-align: center;
  position: relative;
  svg {
    margin-right: 18px;
  }
  &__filled {
    background-color: $s-dark-grey-blue;
    color: #fff;
  }

  &--empty {
    padding: 0 5px;
    min-width: 50px;
    min-height: 50px;
    text-align: center;
    svg {
      margin: 0;
    }
  }

  &__white {
    background-color: $c-white;
    border: solid 1px rgba($c-blue, 0.3);
    color: $s-dark-grey-blue;
  }

  &__link {
    background-color: transparent;
    border: solid 1px transparent;
    color: $s-dark-grey-blue;
    padding: 0;
    margin-left: -28px;
    min-height: 22px;
    height: 22px;
  }

  &--small {
    min-height: 36px;
    height: 36px;
  }

  &__secondary {
    background-color: #f1f2f3;
    border: solid 1px #ccd2d8;
    color: $s-dark-grey-blue;
  }

  &__label
  {
    display: block;
  }


  &--inactive {
    opacity: 0.5;
  }

  &--loading {
    opacity: 0.5;
  }

  &--wide {
    max-width: none;
    width: 100%;
  }
}

@import "variables.scss";
@import "flexbox.scss";

.cancelation {
  @include flexbox();
  @include flex-direction(row);
  @include align-items(center);
  font-size: 14px;
  font-style: oblique;
  color: $s-static-heading;
}
@import "variables.scss";

.tos {
  font-size: 14px;
  font-weight: 500;
  color: $c-blue;

  &__link {
    padding-left: 0.3em;
  }

  &--error {
    color: $c-red;
  }
  label {
    cursor: pointer;
    vertical-align: text-top;
  }

  input {
    margin-right: 4px;
  }

}
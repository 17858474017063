@import "mixins.scss";
@import "flexbox.scss";

.my-services {
  &__products {

    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: solid 1px $s-divider;

    &__title {
      color: $s-dark-grey-blue;
      margin: 0;

      svg {
        transform: translateY(1px);
      }
    }
    &__subtitle {
      color: $s-static-heading;
      opacity: 0.7;
      font-size: 14px;
    }
    &__subscription {
      @include flexbox();
      @include flex-direction(row);
      &__title {
        @include flex(1);
      }
      &__action {
      }
      margin-bottom: 12px;
      & + .my-services__products__buy_link {
        margin-top: 12px;
      }
    }
  }
  &__moresell {
    color: $s-status-text;
    font-size: 14px;
    a {
      color: $s-dark-grey-blue;
      font-weight: bold;
      padding-left: 0.3em;
    }
  }
}
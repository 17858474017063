@import "variables.scss";
@import "flexbox.scss";

.modal {
  @include flexbox();
  position: fixed;
  left: 0;
  right: 0;
  top: 50px;
  bottom: 0;
  background: rgba(256, 256, 256, 0.8);
  @include align-items(center);
  @include flex-direction(column);
  &__container {
    margin-top: 10vh;
    max-width: 529px;
    height: auto;
    border-radius: 4px;
    box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.26);
    background-color: $c-white;

    &__header {
      padding: 16px 32px 0 32px ;

      &__text {
        margin: 0;
        padding-bottom: 8px;
        line-height: 32px;
        border-bottom: solid 1px $c-gray-blue-2;
      }
    }
    &__content {
      padding: 0 32px;
      > * {
        margin: 16px 0 16px 0;
      }
      max-height: 50vh;
      min-height: 20vh;
      overflow: scroll;
    }
    &__footer {
      padding: 0 32px;
      height: 90px;
      border-radius: 4px;
      background-color: $c-gray-blue-3;
    }
    &__actions {
      @include flexbox();
      @include flex-direction(row);
      @include align-items(center);
      justify-content: flex-end;
      > * + * {
        margin-left: 16px;
      }
    }
  }
}


@import "variables.scss";
@import "flexbox.scss";

.registration-wizard {
  &__title {
    margin: 32px 0;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: oblique;
    color: $s-static-heading;
  }
  @include flexbox();
  @include flex-direction(row);
  &__step {
    position: relative;
    @include flex(1);
    @include flexbox();
    @include flex-direction(row);

    @media all and (max-width: $mobile-break-point) {
      margin-bottom: 20px;
    }

    &:nth-child(1) {
      flex: 1 1 28%;
    }
    &:nth-child(2) {
      flex: 1 1 32%;
    }
    &:nth-child(3) {
      flex: 1 1 40%;
      .registration-wizard__step__label {
        margin-right: 0;
      }
    }

    &--active {
      border-color: $s-dark-grey-blue;
      .registration-wizard__step__number {
        background-color: $s-greyblue;
        background: $s-light-green;
        color: $s-text-color;
      }
    }
    &--complete {
      .registration-wizard__step__number {
        background: $s-light-green;
        color: $s-text-color;
      }
    }
    &--pending {
      .registration-wizard__step__number {
        background: $s-light-yellow;
        color: $s-text-color;
      }
    }
    &--not-started {
      .registration-wizard__step__number {
        background-color: rgba($s-greyblue, 0.1);
        color: $s-dark-grey-blue;
      }
    }
    border-bottom: solid 4px $s-divider;
    &__label {
      // margin-top: -7px;
      margin-right: 30px;
      padding-bottom: 21px;
      &__info {
        font-family: Avenir;
        font-size: 18px;
        font-weight: 600;
        color: $s-dark-grey-blue;
        line-height: 19px;
        // white-space: nowrap;
        overflow: hidden;
      }
      &__title {
        opacity: 0.7;
        font-family: Avenir;
        font-size: 14px;
        font-weight: 600;
        color: $s-dark-grey-blue;
        line-height: 25px;
        &[data-pending='true'] {
          font-style: italic;
        }
      }
    }
    &__number {
      @include flex(0 0 36px);
      border-radius: 100%;
      width: 36px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      font-family: Avenir;
      font-size: 21px;
      font-weight: 600;
      margin-top: 4px;
      margin-right: 16px;
    }
  }
}


@media all and (max-width: $mobile-break-point) {
  .registration-wizard {
    @include flex-direction(column);
  }
}

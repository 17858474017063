@import "variables.scss";
@import "flexbox.scss";

.checkblock {

    @include flexbox();
    @include align-items(center);

    img {
      width: 20px;
      height: 18px;
      margin-right: 24px;
      position: absolute;
      top:0;
      left: 0;
    }

    &__icon {
      width: 20px;
      height: 18px;
      margin-right: 24px;
      position: relative;
    }
    &__content {
      @include flexbox();
      @include flex-direction(column);
      &__title {
        font-size: 16px;
        font-weight: 900;
        margin: 0;
        color: #3e3b73;
      }

      &__subtitle {
        opacity: 0.5;
        color: #3e3b73;
        margin-top: 0;
      }
  }
}

@import "variables.scss";
@import "flexbox.scss";

@mixin header() {
  height: 50px;
  background-color: $c-blue;
  color: $c-white;
  @include flexbox();
  @include flex-direction(row);
  @include align-items(center);
  padding: 0 1em;
}


@import "flexbox.scss";

.registration-account {
  &__search_account_exist {
    margin: 12px 0 ;
    @include flexbox();
    @include flex-direction(column);
    > * {
      margin: 12px 0 ;
    }
  }
  &__without__licenses {
    margin: 12px 0 ;
    @include flexbox();
    @include flex-direction(column);
  }
  &__not__found {
    margin: 12px 0 ;
    @include flexbox();
    @include flex-direction(column);
  }
}
.registration {

  &__title {
    font-weight: bold;
  }

  &__get-org {
    @include flexbox();
    .input {
      @include flex(1);
    }
    .button {
      @include flex(1);
      margin-left: 14px;
      padding: 0;
    }
  }

  &__org-result {
    @include flexbox();
    @include align-items(center);
    margin-top: 28px;

    img {
      width: 20px;
      height: 18px;
      margin-right: 24px;
    }

    .registration__org-result__name {
      font-size: 16px;
      font-weight: 900;
      margin: 0;
      color: #3e3b73;
    }

    .registration__org-result__orgno {
      opacity: 0.5;
      color: #3e3b73;
      margin-top: 0;
    }
  }
}
@import "mixins.scss";
@import "variables.scss";
@import "flexbox.scss";

.my-subscriptions {

  margin-top: 14px;

  .offer {
    &__action {
      cursor: pointer;
    }
    &__actions {
      @include flexbox();
      @include flex-direction(row);
      @include align-items(center);
      justify-items: flex-end;
      margin-bottom: 1em;
    }
    &__form {
      margin-left: 46px;
    }
    &__description {
      @include flexbox();
      @include flex-direction(row);
      @include align-items(center);
      margin-bottom: 1em;
      justify-content: space-between;
      &__title {
        margin-left: 1em;
        @include flexbox();
        @include flex-direction(column);
        align-items: flex-start
      }
    }
  }

}


.my-subscriptions-form {
  position: relative;
  &__toggler{
    cursor: pointer;
  }
  &__list {
    position: absolute;
    @include flexbox();
    @include flex-direction(column);
    &--show {
      min-width: 150px;
      border: solid 1px #aaa;
      background: #fff;
    }
    &--hide {
      display: none;
    }
  }
}

.spinner {
  min-height: 200px;
  height: 100%;
  position: relative;
  width: 100%;
  grid-column: 1/13; // In case it's in a grid

  &--narrow {
    min-height: 60px;
  }

  &__inner {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    width: 60px;
    height: 60px;
    z-index: 10;
    svg {
      width: 60px;
      height: 60px;
      circle {
        fill: #000;
      }
    }
  }
}

@import "variables.scss";
@import "flexbox.scss";

.form {
  @include flexbox();
  @include flex-direction(column);
  @include align-items(flex-start);

  &__field {
    text-align: left;
    margin-bottom: 0;
    &__group {
      @include flexbox();
      @include flex-direction(row);
      @include align-items(center);
      @include justify-content(space-between);
      &--right {
        justify-content: flex-end;
        @include align-self(flex-end);
        > * {
          margin-left: 1em;
        }
      }
    }
    &__label {
      // text-transform: uppercase;
      @include flexbox();
      @include flex-direction(row);
      @include align-items(center);
      margin-bottom: 4px;
      font-size: 14px;
      line-height: 16px;
      height: 16px;

      .icon {
        margin-right: 8px;
        margin-bottom: 1px;
        fill: $c-gray-2;
        width: 11px;
      }

      &__text {
        color: $c-gray-2;
      }
    }

    &__align-right {
      text-align: right;
    }
  }

  &__link {
    display: inline-block;
    margin: 0 0 1.5px 0;
    padding-bottom: 0.5px;
    border-bottom: 1px solid;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.5px;
  }

  &__button {
    width: 100%;
    height: 52px;
    line-height: 52px;
    border-radius: 0 0 2px 2px;
    font-size: 14px;
    color: #fff;
    background-color: $c-green;
    -webkit-font-smoothing: antialiased;
  }

}

$font-header: "Avenir", sans-serif;
$font-title: "Publico Headline", georgia, Times, "Times New Roman", serif;
$font-body: $font-header;
$font-weight: 500;

$form_gutter: 14px;

$mobile-break-point: 768px;
$tablet-break-point: 1024px;

$c-gray-blue: #324f70;
$c-gray-blue-2: #ccd2d8;
$c-gray-blue-3: #f3f4f6;

$c-black: #000000;
$c-blue: #004473;
// $c-blue-2: #;
$c-green: #9db783;
$c-red: #a20101;
$c-gray: #cacaca;
$c-white: #ffffff;
$c-paleblue: #e1e8ee;
$c-gray-2: #737373;


$s-greyblue: #7f95ad;
$s-dark-grey-blue: #324f70;
$s-status-text: #737373;
$s-divider: #e6e6e6;
$s-text-color: #ffffff;
$s-static-heading: #4a4a4a;
$s-light-peach: #b6b6b6;
$s-light-periwinkle: #dde0e3;
$s-pinkish-grey: #ccc3ad;
$s-light-grey: #f5f5f5;
$s-light-green: #a2d6a7;
$s-light-brown: #cec3aa;
$s-light-yellow: #f4dc9a;

@import "mixins.scss";
@import "flexbox.scss";

.account-members {
  position: relative;
  &__toggler{
    cursor: pointer;
  }
  &__user {
    padding-bottom: 24px;
    border-bottom: solid 1px $s-divider;
    margin-bottom: 24px;

    &__subscription {
      margin-top: 4px;

      > * {
        margin: 8px 0;
      }
      :first-child {
        @include flex(1);
      }
      @include flexbox();
      @include flex-direction(row);
    }
  }
  &__list {
    position: absolute;
    @include flexbox();
    @include flex-direction(column);
    &--show {
      min-width: 150px;
      border: solid 1px #aaa;
      background: #fff;
    }
    &--hide {
      display: none;
    }
  }
}

.account-pending-members {

  &__user {
    @include flexbox();
    @include flex-direction(row);
    margin-bottom: 31px;
    &__subscription {
      @include flexbox();
      @include flex-direction(row);
      @include align-items(center);
      h4 {
        @include flex(1);
        margin: 4px;
        padding: 0;
      }
      &--reject {
        text-decoration: overline;
      }
    }
    &__content {
      @include flex(1);
      @include flexbox();
      @include flex-direction(column);

      &__subtitle {
        opacity: 0.7;
        font-size: 14px;
        font-weight: 500;
      }
    }
    &__actions {
      @include flexbox();
      @include flex-direction(row);
      button {
        display: inline-block;
        margin-left: 12px;
      }
    }

  }
}

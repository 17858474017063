@import "mixins.scss";
@import "flexbox.scss";

.organization-settings {
  position: relative;
  &__toggler{
    cursor: pointer;
  }
  &__list {
    position: absolute;
    @include flexbox();
    @include flex-direction(column);
    &--show {
      min-width: 150px;
      border: solid 1px #aaa;
      background: #fff;
    }
    &--hide {
      display: none;
    }
  }
}
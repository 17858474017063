@import "variables.scss";
@import "flexbox.scss";

.pending {
  @include flexbox();
  @include flex-direction(row);
  @include align-items(center);
  font-size: 14px;
  font-style: oblique;
  color: $s-static-heading;
  margin: 11px 0;
  svg {
    margin-right: 8px;
  }
}
@import "flexbox.scss";
@import "variables.scss";

.registration-offer {
  @include flexbox();
  @include flex-direction(row);
  // padding-right: 32px;
  align-items: center;
  
  &__image {
    margin-right: 32px;
  }

  &__details {
    @include flex(1);
    &__title {
      font-size: 32px;
      font-weight: 500;
      display: flex;
      align-items: center;
    }
    &__pretitle {
      opacity: 0.7;
      font-size: 12px;
      font-weight: 900;
      letter-spacing: 1px;
      margin-left: 8px;
    }

    &__amount {
      font-size: 18px;
      font-weight: 900;
    }
  }
  img {
    max-height: 150px;
  }

  &-price {
    &__duration {
      font-size: 18px;
      font-weight: 500;
      color: $s-status-text;
    }

  }

}
@import "variables.scss";
@import "flexbox.scss";

.checkbox {
  cursor: pointer;
  @include flexbox();
  @include align-items(center);
  color: rgba($c-blue, 1);
  box-sizing: border-box;
  border: solid 1px $c-gray-blue-2;
  border-radius: 4px;
  height: 51px;
  font-size: 14px;
  padding: 0 15px;
  padding: 0 $form_gutter;
  background: $c-gray-blue-3;
  vertical-align: middle;
  font-size: 16px;
  position: relative;
  &__label {
    padding-left: 15px;
    padding-right: 5px;
    &::before {
      content: '';
      position: absolute;
      border-radius: 2px;
      border: solid 1px $s-greyblue;
      top: 15px;
      left: 10px;
      width: 16px;
      height: 16px;
      background-color: $s-text-color;
    }
}
  &__input {
    display: none;

    &:checked + .checkbox__label {
      &::after {
        content: '';
        position: absolute;
        border-radius: 2px;
        top: 18px;
        left: 13px;
        width: 12px;
        height: 12px;
        background-color: $s-greyblue;
      }
    }
  }


  &__label
  {
    display: block;
    color: $c-blue;
    margin-left: 8px;
    cursor: pointer;
    user-select: none;
  }

}

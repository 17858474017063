@import "variables.scss";

.feedback {
  &--error
  {
    color: $c-red;  
  }

  &--submit
  {
    margin-top: 24px;
    padding-top: 24px;
    border-top: solid 1px $c-gray;
    font-style: italic;
  }
}
@import "flexbox.scss";

.login {
  @include flexbox();
  @include flex-direction(column);

  &__title {

  }

  &__email {

  }

  &__password {

  }

  &__button {
    @include align-self(flex-end);
  }

}
@import "mixins.scss";
@import "variables.scss";
@import "flexbox.scss";

.menu
{
  @include flexbox();
  @include flex-direction(row);
  color: $c-white;

  a {
    color: $c-white;
    text-decoration: none;
    margin-left: 1em;
  }
}
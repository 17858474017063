@import "variables.scss";

.field {
  display: block;
  width: 100%;
  &__label
  {
    display: block;
    color: $c-blue;
  }
  &__field {
    width: 100%;
    color: rgba($c-blue, 1);
    box-sizing: border-box;
    background: $c-gray-blue-3;
    border-radius: 4px;
    height: 48px;
    font-size: 14px;
    padding: 0 15px;
    padding: 0 $form_gutter;
    line-height: 48px;
  }

}

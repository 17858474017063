@import "variables.scss";

.input {
  display: block;
  width: 100%;
  &__label
  {
    display: block;
    color: $c-blue;
  }
  &__input {
    width: 100%;
    color: rgba($c-blue, 1);
    box-sizing: border-box;
    border: solid 1px $c-gray-blue-2;
    border-radius: 4px;
    height: 51px;
    font-size: 14px;
    padding: 0 15px;
    padding: 0 $form_gutter;
    background: $c-gray-blue-3;
    &::placeholder {
      font-size: 16px;
      color: rgba($c-blue, 0.4);
    }

    &:invalid {
      // border: 1px solid red;
    }
  }
  &--error {
    .input__input {

    }
  }

}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: solid 1px rgba($c-blue, 0.25);
  -webkit-text-fill-color: $c-blue;
  -webkit-box-shadow: 0 0 0 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}

@import "variables.scss";
@import "flexbox.scss";


/*

.site-header {
  @include flex(1);
  @media screen and (min-width: 650px) {

  }
}

.site-header + .route-header{
  margin-bottom: 8px;
}

*/

.site-header {
  @include flexbox();
  @include flex-direction(row);
  @include align-items(center);
  @include justify-content(space-between);
  height: 50px;
  margin: 0;
  background-color: $c-blue;
  color: $c-white;
  padding: 0 1em;
  padding: 0 24px;
  font-size: 13px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  svg {
    fill:white;
  }

  &__logo {
    @include flexbox();
    @include flex-direction(row);
    @include align-items(center);
    height: 50px;
    width: 200px;
    margin: auto 0;
    svg {
      height: 19px;
      width: 167px;
      fill:white;
      float:left;
    }
  }

  &__nav {
    @include flexbox();
    @include justify-content(flex-end);
    @include align-items(center);
    svg {
      display: inline;
    }

    &__item {
      margin-left: 12px;
      margin-right: 12px;
      position: relative;

      @media all and (max-width: $tablet-break-point) {
        margin-left: 0;
        margin-right: 0;
        &:nth-child(2) {
          > a {
            svg {
              margin-left: 0.8em;
            }
          }
        }
      }

      > a {
        @include flexbox();
        @include flex-direction(row);
        @include align-items(center);
        svg {
          margin-left: 0.5em;
          max-height: 14px;
        }
        // .icon--person {
        //   margin-top: -2px;
        // }
      }

      &__label {
        @media all and (max-width: $tablet-break-point) {
          display: none;

        }
      }
    }
  }
}



.registration {
  margin-top: 12px;

  &__title {
    font-weight: bold;
  }
}

.response {
  border-top: 1px solid #979797;
  margin: 24px 0;
  padding-top: 24px;
}
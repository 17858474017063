@import "variables.scss";
@import "flexbox.scss";

.notification {
  @include flexbox();
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100px;
  background: $s-greyblue;
  @include align-items(center);
  justify-content: center;
  @include flex-direction(row);
  color: #fff;
  padding: 0 32px;
  font-size: 18px;

  &--open {
    transform: translateY(0px);
    transition: transform 0.1s ease;
  }
  &--close {
    transform: translateY(100px);
    transition: transform 0.1s ease;
  }
  &__left {
    @include flex(1);
  }
  &__content {
    @include flex(10);
    @include flexbox();
    @include flex-direction(row);
    @include align-items(center);
    justify-content: center;
  }
  &__right {
    @include flex(1);
    @include flexbox();
    @include flex-direction(row);
    @include align-items(center);
    justify-content: flex-end;
    cursor: pointer;
  }
  svg {
    width: 30px;
    height: 30px;
    transform: rotate(45deg);
    color: $s-text-color;
    fill: $s-text-color;
  }
}


@import "mixins.scss";
@import "flexbox.scss";

.app-products {
  background-color: #22446a;


  main {
    // min-width: 848px;
    // max-width: 848px;
    // margin-top: 20vh;
  }
}
.products {
  // position: relative;
  // &__toggler{
  //   cursor: pointer;
  // }
  // &__list {
  //   position: absolute;
  //   @include flexbox();
  //   @include flex-direction(column);
  //   &--show {
  //     min-width: 150px;
  //     border: solid 1px #aaa;
  //     background: #fff;
  //   }
  //   &--hide {
  //     display: none;
  //   }
  // }
}

.product {
  background-image: linear-gradient(to bottom, #667898, #15406b);
  width: 100%;
  color: $c-white;
  padding: 65px 0px 50px 0px;

  &__title {
    margin-top: 0;
    text-align: center;
    font-family: $font-title;
    font-size: 32px;
    img {
      max-width: 250px;
    }
  }
  &__description {
    max-width: 680px;
    text-align: center;
    margin: 0 auto 30px auto;
    font-size: 18px;

    // @media all and (max-width: $mobile-break-point) {
    //   padding: 0 20px;
    // }
  }

  &__button {
    padding: 10px;
    background-color: white;
    border-radius: 4px;
    border: solid 1px $s-greyblue;
    background-color: $s-dark-grey-blue;
    color: $c-white;
    width: 100%;
    display: block;
    text-align: center;
    // margin-top: 50px;
  }

}

.other-products {
  list-style: none;
  // @include flexbox();
  // @include flex-direction(column);
  background-color: #22446a;
  width: 100%;
  padding: 50px 0px;
  margin-bottom: 50px;
  color: $c-white;



  &__title {
    margin-top: 0;
    font-size: 36px;
    text-align: center;
    font-family: $font-header;
    font-weight: 500;
    svg {
      max-width: 250px;
    }
  }

  &__list {
    @include flexbox();
    @include justify-content(space-between);

    @media all and (max-width: $mobile-break-point) {
      @include flex-direction(column);
    }

    &__item {
      // background-color: white;
      @include flexbox();
      @include justify-content(center);
      // @include align-items(center);
      @include flex-direction(column);
      @include flex(0 1 50%);
      text-align: center;
      padding: 0 20px;

      @media all and (max-width: $mobile-break-point) {
        padding: 0;
        & + .other-products__list__item {
          margin-top: 50px;
        }
      }
      > .product__title {
        img {
          max-height: 32px;
          vertical-align: middle;
        }
      }
      > img {
        @include align-self(center);
        max-width: 100%;
        max-height: 250px;
      }

      &__button {
        margin-top: 10px;
        width: auto;
      }
    }
  }

}

$col-color-2: #28496f;
$col-color-3: #4f7095;

.product-features {

  &__image {
    text-align: center;
    img {
      width: 100%;
      max-height: 500px;
    }
  }
  &__table {
    @media all and (max-width: $mobile-break-point) {
      display: none;
    }
  }
  &__header-row,
  &__footer-row {
    @include flexbox();
    @include justify-content(space-between);
  }
  &__header-row {
    .product-features__value-col {
      &:nth-last-child(1),
      &:nth-last-child(2),
      &:nth-last-child(3) {

        padding: 12px 0px;
      }
      &:nth-last-child(1) {
        background-color: rgba($col-color-3, 0.5);
        border-radius: 0px 10px 0px 0px;
      }
      &:nth-last-child(2) {
        background-color: rgba($col-color-2, 0.5);
        border-radius: 10px 0px 0px 0px;
      }
    }
  }
  &__feature-row {
    @include flexbox();
    @include justify-content(space-between);
    color: white;
    &:nth-child(even) {
      background-color: #4b678a;
      border-radius: 4px;
    }
    .product-features__value-col {
      &:nth-last-child(1) {
        // background-color: rgba(red, 0.5);
      }
    }
    .product-features__value-col {
      &:nth-last-child(2) {
        background-color: rgba($col-color-2, 0.5);
      }
    }
    .product-features__value-col {
      &:nth-last-child(1) {
        background-color: rgba($col-color-3, 0.5);
      }
    }
  }
  &__footer-row {
    .product-features__value-col {
      &:nth-last-child(3) {
        // background-color: rgba(red, 0.5);
      }
      &:nth-last-child(2) {
        background-color: rgba($col-color-2, 0.5);
      }
      &:nth-last-child(1) {
        background-color: rgba($col-color-3, 0.5);
      }
      &:nth-last-child(2) {
        border-radius: 0px 0px 0px 10px;
      }
      &:nth-last-child(1) {
        border-radius: 0px 0px 10px 0px;
      }
    }
  }

  &__first-col {
    @include flex(0 0 35%);
    padding: 15px 15px;
    color: #d6dbe0;
    line-height: 1;
  }

  &__value-col {
    @include flex(0 1 35%);
    text-align: center;
    position: relative;
    // padding: 5px 10px;

    &__price {
      font-size: 24px;
    }
    &__button {
      width: auto;
      margin-top: 10px;
    }
    .product-features__header-row & {
      font-size: 24px;

    }
    .product-features__footer-row & {
      padding: 15px;
    }
  }
  &__checked {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__unchecked {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    svg path {
      fill: $s-greyblue;
    }
  }


  // &__checked {
  //   @include flexbox();
  //   &::after {
  //     content: attr(data-attr);
  //   }
  // }

  &__mobile {
    display: none;
    max-width: 500px;
    margin: 0 auto;
    background-color: rgba($col-color-2, 0.5);
    padding: 20px;
    border-radius: 10px;

    & + .product-features__mobile {
      margin-top: 50px;
    }


    &__title,
    &__price__text {
      color: #d6dbe0;
      font-size: 24px;
      @media all and (max-width: $mobile-break-point) {
        text-align: center;
      }
    }

    &__price__button {
      width: auto;
      margin-top: 10px;
    }

    @media all and (max-width: $mobile-break-point) {
      display: block;
    }
  }

}

.product-avis {
  @include flexbox();
  @include justify-content(center);

  @media all and (max-width: $mobile-break-point) {
    @include flex-direction(column);
  }

  &__item {
    @include flexbox();
    @include flex(0 1 50%);
    @include flex-direction(column);
    padding: 0px 20px;

    & + & {
      border-left: solid 1px rgba(#667898, 0.5);
    }

    @media all and (max-width: $mobile-break-point) {
      &:first-child {
        border-right: none;
      }

      padding: 0;
      & + .product-avis__item {
        margin-top: 50px;
      }
    }


    img {
      @include align-self(center);
      display: block;
      max-width: 100%;
      max-height: 250px;
    }

    &__types {
      @include flexbox();

      &__text {
        padding: 16px;
        cursor: pointer;
        border-radius: 4px;
        @include flexbox();
        position: relative;
        &::before {
          content: '';
          border-radius: 50%;
          margin-right: 8px;
          width: 24px;
          height: 24px;
          border: solid 1px #8095ad;
          background-color: #2e4a6b;
          flex-shrink: 0;
        }
      }

      input {
        display: none;
        &:checked + .product-avis__item__types__text{
          background-color: $s-dark-grey-blue;
          &::after {
            content: '';
            position: absolute;
            border-radius: 50%;
            top: 7.5px;
            left: 7.5px;
            width: 11px;
            height: 11px;
            background-color: $c-white;
            flex-shrink: 0;
            margin-top: 16px;
            margin-left: 16px;
          }
        }
      }
    }
    &__typetext {
      font-size: 11px;
      font-style: oblique;
      margin-top: 10px;
      color: #b7c1cd;
    }
  }

  &__actions {
    line-height: 1.2rem;
  }
  &__list {
    margin: 30px 0px 0px 0px;
    padding: 0;
    color: #d6dbe0;
    @include flex-grow(1);
    @include flexbox();
    @include flex-direction(column);

    @media all and (max-width: $mobile-break-point) {
      @include flexbox();
      @include flex-direction(column);
      @include align-items(flex-start);
    }

    &__item {
      @include flexbox();
      @include align-items(center);
      margin-bottom: 20px;
      font-weight: 500;
      line-height: normal;
      svg {
        @include flex(0 0 21px);
        margin-right: 16px;
      }
    }
  }

  &__title {
    // font-size: 24px;
    // font-weight: 500;
    text-align: center;
    font-size: 24px;
  }
  &__subtitle {
    // margin-top: 0;
    text-align: center;
    color: $c-white;
    text-transform: uppercase;
    opacity: 0.5;
    font-weight: 500;
    font-size: 13px;
  }

  &__button {
    @include flexbox();
    @include align-items(center);
    @include justify-content(space-between);
    padding: 0;
    width: auto;
    min-height: 48px;
    padding: 16px 24px;
    font-weight: 900;

    &__name {
      text-align: left;
    }
    &__amount {
      text-align: right;
      @include flex(0 0 30%);
    }
    &__currency {
      opacity: 0.5;
    }
    &__period {
      font-size: 13px;
      opacity: 0.5;
    }

    & + .product-avis__button {
      margin-top: 20px;
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
      background-color: #f3f6f9;
      color: $c-blue;
    
    }
  }
}
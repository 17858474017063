
.infobox {
  background: #f0f4f8;
  margin: 0;
  margin-top: 14px;
  margin-bottom: 8px;
  padding: 24px;
  border: 1px solid #c0d1dc;
  > div {
    margin-bottom: 1em;
  }
}

.registration {

  &__title {
    font-weight: bold;
  }

  &__orgfields {
    border: none;
    background-color: #fff3de;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    padding: 24px;
    margin-top: 24px;
  }

  &__orgfields__legend {
    font-weight: 800;
    margin-top: 24px;
    font-size: 18px;
  }

  &__orgfields__legend::after {
    clear: both;
  }
}
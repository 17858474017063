@import "flexbox.scss";

.update_user_subscription {
  &__row {
    @include flexbox();
    @include flex-direction(row);
    > * + * {
      margin-left: 12px;
    }
  }
}